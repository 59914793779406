import Button from "../../components/UI/Button";
import React from "react";
import {generateMonthlySalesReport} from "../../api/report";
import { saveAs } from 'file-saver';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const OrderReportGenerateButton = ({params}) => {
    const [loading, setLoading] = React.useState(false);

    const generateReportHandler = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            const response = await generateMonthlySalesReport({...params});
            const byteCharacters = atob(response);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            if(params.month) {
                saveAs(blob, `Monthly_Sales_Report_${params.month}_${params.year}.xlsx`);
            } else {
                saveAs(blob, `Order-sales-report-for-${params.vendor_id}-${params.start_date}-to-${params.end_date}.xlsx`);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error generating report:', error);
        }
    }
    // ${loading && }
    return (
        <>
            {loading ?
                <button className={'btn height_40p btn_black btn_md '}  >
                    Download Report <span className={'ml_5'}><FontAwesomeIcon icon={faSpinner} spin/></span>
                </button>
                :
                <Button text={`Download Report`}
                        classes={'height_40p'}
                        onClickHandler={generateReportHandler} />
            }
        </>

    );
}

export default OrderReportGenerateButton;