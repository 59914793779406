import React from "react";
const MonthlyReportSummary = ({orders}) => {
  return (
      <>
          <div className="table_responsive report_table">
              <table className="table">
                  <thead>
                  <tr>
                      <th>Vendor Name</th>
                      <th>Order Total</th>
                      <th>Item Total</th>
                      <th className={'text_right'}>Sales Total</th>
                  </tr>
                  </thead>
                  {orders.length ?
                      <tbody>
                      {orders.map((order, index) => (
                          <tr key={order.id}>
                              <td>{order.VendorName}</td>
                              <td>{order.Order_total}</td>
                              <td>{order.Item_total}</td>
                              <td className={'text_right'}>${order.Sales_total }</td>
                          </tr>
                      ))}
                      </tbody>
                      :
                      <tbody>
                      <tr>
                          <td colSpan={9} className={'text_center'}>No data found.</td>
                      </tr>
                      </tbody>
                  }
              </table>
          </div>
      </>
  )
}

export default MonthlyReportSummary;