import React, { useState, useEffect } from 'react';
import Card from "../../components/UI/Card";
import Button from "../../components/UI/Button";
import {getReportedOrders} from "../../api/report";

import MonthlyReportSummary from "../../views/report/monthly-report/MonthlyReportSummary";
import OrderReportGenerateButton from "../../views/report/OrderReportGenerateButton";

export default function MonthlySalesByReport() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
  const [orders, setOrders] = useState([]);
  const [requestHasSend, setRequest] = useState(false);

  const [form, setForm] = useState({
    per_page: 20,
    page: 1,
    this_month: true,
    month: currentMonth,
    year: currentYear,
  });

  const sendReportRequest = async () => {
    setRequest(true);
    const {data, summary} = await getReportedOrders({ ...form });
    setOrders(data)
  }

  useEffect(() => {
    sendReportRequest();
  }, [form.page]);

  const changePage = async (page) => {
    setForm((old) => ({...old, page}));
  }

  const handleOnchange = (value, column) => {
    setForm((old) => ({...old, [column]: value}));
  }

  return (
      <Card title="Monthly Report">
        <div className="row">
          <div className="col_2">
            <select className="form_global"
                    value={form.month}
                    onChange={(e) => handleOnchange(e.target.value, 'month')}>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>

          <div className="col_2">
            <select className="form_global"
                    value={form.year}
                    onChange={(e) => handleOnchange(e.target.value, 'year')}>
              {years.map(year => (
                  <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>

          <div className="col_4">
            <Button text="Submit" classes={'height_40p'} onClickHandler={sendReportRequest} />
          </div>
        </div>
        {requestHasSend &&
            <>
              <div className="row mt_30 mb_30">
                {orders.length ?
                    <div className="col_12 mb_10">
                      <div className="d_flex_end">
                        <OrderReportGenerateButton params={form} />
                      </div>
                    </div>
                    : ''}
                <div className="col_12">
                  {orders && orders.length &&  <MonthlyReportSummary orders={orders} />}
                </div>
              </div>
            </>
        }
      </Card>
  );
}