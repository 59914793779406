import Card from "../../../components/UI/Card";
import {useEffect, useState} from "react";
import {getOrderTypes, orderStatusUpdate} from "../../../api/orders";
import {useMutation} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {checkFeatureAvailability} from "../../../util/helper";

const OrderStatusAction = ({order, changeStatus}) => {
    const [orderStatus, setOrderStatus] = useState([]);
    const [status, setStatus] = useState('');
    const params = useParams();

    const {mutate, isPending} = useMutation({
        mutationFn: () => getOrderTypes(),
        onSuccess: (data) => {
            setOrderStatus(data)
        }
    })

    useEffect(()=>{
        if(!orderStatus?.length){
            mutate()
        }
    }, [])

    const handleStatusChange = (async (event) => {
        let selectedStatus = event.target.value
        setStatus(selectedStatus)
        let res = await orderStatusUpdate(params.id, {status: selectedStatus});
        if (res.success) {
            if(changeStatus){
                changeStatus(+selectedStatus)
            }
            toast.success(res.message)
        } else  {
            toast.error(res.message)
        }
    })

    useEffect(()=>{
        setStatus(order.status)
    }, [order])

  return (
      <>
        {(order) && <Card>
          <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">Order Status</h1>
          <div>
            <div className="form_input">
              <select className="form_global" value={status} onChange={handleStatusChange}  disabled={!checkFeatureAvailability(order, 'order-status', params) ? true : false}>
                <option value="">Select Status</option>
                {Object.entries(orderStatus).length &&
                 <>
                     {Object.entries(orderStatus).map(([key, value]) => (
                         <option key={`option_item_${ orderStatus[key].id }`} value={ orderStatus[key].id }>
                             { orderStatus[key].type }
                         </option>
                     ))}
                 </>
                }
              </select>
            </div>
          </div>

        </Card>}
      </>
  );
}

export default OrderStatusAction