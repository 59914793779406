export const activeRoute = (location) => {
    let keys = location.split('/')
    if(keys && keys.length > 0)
        keys = keys.filter(Boolean)

    return keys
}

export const findObjectById = (items, targetId, nestedArrayKey = '') => {
    for (const category of items) {
        if (category.id === targetId) {
            return category;
        }

        const foundObject = findObjectById(category[nestedArrayKey], targetId);
        if (foundObject) {
            return foundObject;
        }
    }

    return null;
}

export const unstructureJson = (data) => {
    let string = ''
    if(data){
        let array = [];
        try {
            array = JSON.parse(data)
        } catch (e){
            if(data && data.length){
                array = data
            }
        }

        if(array && array.length) {
            array.map((item) => {
                string += `${item.name}:${item.value} `
            })
        }
    }

    return string;
}

export const noImagePlaceholder = (e) => {
    return e.target.src = "/images/no-image.png"
}

export const OrderStatusString = (status) => {
    let string = ''
    switch(status) {
        case 1:
            string = "New Order"
            break;
        case 2:
            string = "Confirm Order"
            break;
        case 3:
            string = "Shipped Order"
            break;
        case 4:
            string = "Canceled Order"
            break;
        case 5:
            string = "Completed Order"
            break;
        default:
            string = "Unknown"
    }

    return string;
}

export const checkFeatureAvailability = (order, feature, params) =>{
    let status = params.id === undefined ? true : false

    if(feature === 'default'){
        if(order.payment_method === 'cod' && order.status === 1)
            status = true

    }else if(feature === 'shipping_method'){
        if(order.status === 1)
            status = true

    } else if (feature === 'note'){
        if(order.status === 1)
            status = true

    } else if (feature === 'add-product'){
        if(order.status === 1)
            status = true

        if(order.payment_method === 'cod' && order.payment_status != 'success' && order.status === 1)
            status = true

        if(order.payment_method === 'stripe' )
            status = false

    } else if (feature === 'coupon'){
        if(order.status === 1)
            status = true

        if(order.payment_method === 'cod' && order.payment_status != 'success' && order.status === 1)
            status = true

        if(order.payment_method === 'stripe' )
            status = false

    } else if (feature === 'order-status'){
        if(order.status != 5)
            status = true

    }


    return status
}