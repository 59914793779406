import API from "../util/api";

export const generateMonthlySalesReport = async (payload) => {
  let data = null;

  await API.post('/report/monthly-sales-by-brand', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getReportedOrders = async (payload) => {
  let data = null;
  let summary = null;

  await API.post('/report/orders', payload)
    .then(res => {
      data = res.data.reports;
      summary = res.data.summary;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return {data, summary};
}