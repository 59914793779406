import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../components/UI/Table";
import {getOrders} from "../../api/orders";
import { useSelector} from "react-redux";
import OrderFilterComponent from "./includes/OrderFilterComponent";
import PreloaderComponent from "../../components/UI/PreloaderComponent";
import React, {useState} from "react";
import OrderTableActionComponent from "./includes/OrderTableActionComponent";
import Button from "../../components/UI/Button";
import OrderTableStatusComponent from "./includes/OrderTableStatusComponent";

const OrdersListComponent = ({orderTypes})=> {
  const store = useSelector(state => state.orders)
  const [isFetch, setIsFetch] = useState(false)

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: 'Date '
    }),
    columnHelper.accessor('order_number', {
      id: 'order_number',
      header: 'Order Number '
    }),
    columnHelper.accessor('customer.name', {
      id: 'customer.name',
      header: 'Customer Name',
      cell: props => <span> {props.row.original.customer ? props.row.original.customer.name : props.row.original.shipping_name} </span>
    }),
    columnHelper.accessor('vendor.name', {
      id: 'vendor.name',
      header: 'Vendor Name'
    }),
    columnHelper.accessor('shipping_method.name', {
      id: 'shipping_method.name',
      header: 'Shipping Method'
    }),
    columnHelper.accessor('payment_method', {
      id: 'payment_method',
      header: 'Payment Method',
      cell: props => <span>{`${props.row.original.payment_method === 'cod' ? "Cash On Delivery" : 'Card'}`}</span>
    }),
    columnHelper.accessor('vendor_commission_rate', {
      id: 'vendor_commission_rate',
      header: 'Commission Rate',
      cell: props => <Button text={`${props.row.original.vendor_commission_rate ? props.row.original.vendor_commission_rate : 0}% `} classes={'btn_black'} size={'btn_sm has_border_radius'} />
    }),
    columnHelper.accessor('vendor_commission_amount', {
      id: 'vendor_commission_amount',
      header: 'Commission Amount',
      cell: props => <span> ${props.row.original.vendor_commission_amount ? props.row.original.vendor_commission_amount.toFixed(2) : '0.00'} </span>
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: props => <OrderTableStatusComponent id={props.row.original.id} order={props.row.original} />
    }),
    columnHelper.accessor('total', {
      id: 'total',
      header: 'Total',
      cell: props => <span> ${props.row.original.total.toFixed(2)} </span>
    }),

    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <OrderTableActionComponent id={props.row.original.id} />
    }),
  ];

  const getOrderStatus = (type) => {
    let row = '';
    if(orderTypes){
      Object.keys(orderTypes).forEach(key => {
         if(orderTypes[key].id === type){
           row = orderTypes[key].type
         }
      });
    }
    return row;
  }
  const buttonClass = (type) => {
    let row = '';
    if(orderTypes){
      Object.keys(orderTypes).forEach(key => {
        if(orderTypes[key].id === type){
          if(orderTypes[key].type === 'New Order')
            row = ' btn_warning'
          if(orderTypes[key].type === 'Confirm Order')
            row = 'btn_info'
          if(orderTypes[key].type === 'Shipped Order')
            row = 'btn_primary'
          if(orderTypes[key].type === 'Canceled Order')
            row = 'btn_danger'
          if(orderTypes[key].type === 'Order Complete')
            row = 'btn_success'
        }
      });
    }
    return row;
  }

  return(
    <>
      <OrderFilterComponent />
      <Table
        queryKey={"orders"}
        columns={columns}
        setIsFetched={setIsFetch}
        queryParams={{...store.initialParams, type: store.activeTab}}
        queryFn={getOrders}
      ></Table>
      {isFetch && <PreloaderComponent />}
    </>
  );
}

export default OrdersListComponent;