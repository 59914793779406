import {useQuery} from "@tanstack/react-query";
import 'jquery-ui/ui/widgets/datepicker';
import {getVendors} from "../../../api/vendor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const CustomReportFilter = ({params, setParams}) => {
    const handlePerPage = (e) => {
        setParams({...params,  per_page: e.target.value, page:1});
    }

    const {data: vendors} = useQuery({
        queryKey:['vendors'],
        queryFn: () => getVendors(),
        initialData: () => []
    })

    const filterVendor = (event) => {
        setParams({...params,  vendor_id: event.target.value, page:1});
    }

    const inputChange = (value, column) => {
        let date = value.toISOString().split('T')[0];
        setParams({...params,  [column]: date, page:1});
    }

    return (
        <>
            <div className="color_filter_content">
                <div className="row">
                    <div className="col_12">
                        <div className="form_row">
                            <div className="form_input">
                                <select className="form_global width_full mr_10" value={params.vendor_id} onChange={(event)=>{filterVendor(event)}}>
                                    <option value="">All Vendors</option>
                                    {vendors.data && vendors.data.length > 0 ?
                                        vendors.data.map(item => <option key={'vendor_'+item.id} value={item.id}>{item.name}</option> ) : ''
                                    }
                                </select>
                                <div className="form_input datepicker_global width_full mr_10">
                                    <DatePicker placeholderText={'Start Date'}
                                                selected={params.start_date}
                                                onChange={(date) => inputChange(date, 'start_date')}
                                                showMonthYearDropdown/>
                                </div>
                                <div className="form_input datepicker_global width_full mr_10">
                                    <DatePicker placeholderText={'End Date'}
                                                selected={params.end_date}
                                                onChange={(date) => inputChange(date, 'end_date')}
                                                showMonthYearDropdown/>
                                </div>

                                <select className="form_global width_full" value={params.per_page} onChange={(event)=>{handlePerPage(event)}}>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="86">86</option>
                                    <option value="100">100</option>
                                </select>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomReportFilter