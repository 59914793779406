import React, {useEffect, useState} from "react";
import Card from "../../components/UI/Card";
import {createColumnHelper} from "@tanstack/react-table";
import {getReportedOrders} from "../../api/report";
import Button from "../../components/UI/Button";
import {OrderStatusString} from "../../util/helper";
import CustomReportFilter from "../../views/report/custom-report/CustomReportFilter";
import MonthlyReportSummary from "../../views/report/monthly-report/MonthlyReportSummary";
import OrderReportGenerateButton from "../../views/report/OrderReportGenerateButton";
import CustomReportSummary from "../../views/report/custom-report/CustomReportSummary";
const CustomReport = () => {

    const columnHelper = createColumnHelper();

    const columns = [
        columnHelper.accessor('created_at', {
            id: "created_at",
            header: "Date"
        }),
        columnHelper.accessor('vendor.name', {
            id: 'vendor.name',
            header: 'Vendor Name'
        }),
        columnHelper.accessor('customer.name', {
            id: 'customer.name',
            header: 'Customer Name'
        }),
        columnHelper.accessor('order_number', {
            id: 'order_number',
            header: 'Order Number'
        }),

        columnHelper.accessor('status', {
            id: 'status',
            header: 'Status',
            cell: props => <span>{props.row.original.status ? OrderStatusString(props.row.original.status) : null}</span>
        }),

        columnHelper.accessor('payment_method', {
            id: 'payment_method',
            header: 'Payment Method',
            cell: props => <span>{`${props.row.original.payment_method === 'cod' ? "Cash On Delivery" : 'Card'}`}</span>
        }),
        columnHelper.accessor('vendor_commission_rate', {
            id: 'vendor_commission_rate',
            header: 'Commission Rate',
            cell: props => <Button text={`${props.row.original.vendor_commission_rate ? props.row.original.vendor_commission_rate : 0}% `} classes={'btn_black'} size={'btn_sm has_border_radius'} />
        }),

        columnHelper.accessor('sub_total', {
            id: 'sub_total',
            header: 'Sub Total',
            cell: props => <span> ${props.row.original.sub_total.toFixed(2)} </span>
        }),
        columnHelper.accessor('total', {
            id: 'total',
            header: 'Total',
            cell: props => <span> ${props.row.original.total.toFixed(2)} </span>
        }),
        columnHelper.accessor('vendor_commission_amount', {
            id: 'vendor_commission_amount',
            header: 'Commission Amount',
            cell: props => <span className={"text_right width_full"}> ${props.row.original.vendor_commission_amount ? props.row.original.vendor_commission_amount.toFixed(2) : '0.00'} </span>
        }),

    ];

    const [orders, setOrders] = useState([]);
    const [summary, setSummary] = useState(null);

    const [params, setParams] = useState({
        per_page: 20,
        page: 1,
        start_date: '',
        end_date: '',
        vendor_id: '',
        this_month: false,
    })

    useEffect( () => {
        sendReportRequest();
    }, [params]);

    const sendReportRequest = async () => {
        if(params.start_date && params.end_date) {
            const {data, summary} = await getReportedOrders({...params});
            setOrders(data)
            setSummary(summary)
        }
    }

    return (
        <>
            <Card title={`${'Custom Report'}`}>
                <CustomReportFilter params={params}  setParams={setParams} />
                {orders ?
                    <div className="d_flex_end mb_10">
                        <OrderReportGenerateButton params={params} />
                    </div>
                    : ''}
                {orders &&  <CustomReportSummary orders={orders} summary={summary} params={params} />}
            </Card>
        </>
    );
}

export default CustomReport;