import React, {Fragment} from "react";

const CustomReportSummary = ({orders, summary, params}) => {
    return (
        <>
            <div className="table_responsive">
                <table className="table">
                    <thead>
                    <tr>
                        {params.vendor_id === '' &&
                        <th className={"text_left"} style={{"width":'20%'}}>Vendor Name</th>
                        }
                        <th className={"text_left "} style={{"width":'20%'}}>Order Status</th>
                        <th className={"text_left"} style={{"width":'20%'}}>Order Total</th>
                        <th className={"text_left"} style={{"width":'20%'}}>Item Total</th>
                        <th className={"text_right"} style={{"width":'20%'}}>Sales Total</th>
                    </tr>
                    </thead>
                    {orders ?
                        <tbody>
                        {Object.keys(orders).map((vendor, index) => (
                            <Fragment>
                                <tr key={index}>
                                    {params.vendor_id === '' &&
                                    <td className={"text_left "}  style={{"width":'20%'}}>{vendor}</td>
                                    }
                                    <td colSpan={5} className={'pl_0 pr_0'}>
                                        <table className={'table m_0 p_0 border_none'}>
                                            <tbody>
                                            {orders[vendor].map((order, i) => (
                                                <tr key={i}>
                                                    <td className={"text_left border_none"} style={{"width":'20%'}}>{order.order_status}</td>
                                                    <td className={"text_left border_none"} style={{"width":'20%'}}>{order.order_total}</td>
                                                    <td className={"text_left border_none"} style={{"width":'20%'}}>{order.item_total}</td>
                                                    <td className={"text_right border_none"} style={{"width":'20%'}}>${order.sales_total.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                {summary && summary[vendor] && params.vendor_id === '' &&
                                    <tr style={{'background':'#f2f2f2', 'font-weight':'600'}}>
                                        <td colSpan={2} className={"text_left "} style={{"width": '20%' }}>{vendor} Total</td>
                                        <td className={"text_left"} style={{"width": '20%'}}>{summary[vendor].order_total}</td>
                                        <td className={"text_left"} style={{"width": '20%'}}>{summary[vendor].item_total}</td>
                                        <td className={"text_right"} style={{"width": '20%'}}>${summary[vendor].sales_total }</td>
                                    </tr>

                                }
                            </Fragment>
                        ))}
                        {summary && summary.total && orders ?
                            <tr style={{'background':'#000', 'font-weight':'bold', 'color':'#fff'}}>
                                <td colSpan={params.vendor_id === '' ? 2 : 1 } className={"text_left "} style={{"width": '20%' }}>  Total</td>
                                <td className={"text_left"} style={{"width": '20%'}}>{summary.total.order_total}</td>
                                <td className={"text_left"} style={{"width": '20%'}}>{summary.total.item_total}</td>
                                <td className={"text_right"} style={{"width": '20%'}}>${summary.total.sales_total }</td>
                            </tr> : ''

                        }

                        </tbody>
                        :
                        <tbody>
                        <tr>
                            <td colSpan={9} className={'text_center'}>No data found.</td>
                        </tr>
                        </tbody>
                    }
                </table>
            </div>
        </>
    )
}

export default CustomReportSummary;