import {useLoaderData} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {orderStatusUpdate} from "../../../api/orders";

const OrderTableStatusComponent = ({id, order}) =>{
  const [toastId, setToastId] = useState(null);

  const {orderType} = useLoaderData();
  const queryClient = useQueryClient();

  const {mutate} = useMutation({
    mutationFn: (data)=>orderStatusUpdate(id, data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Order Status!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'orders',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const handleStatusChange = (event) => {
    let selectedStatus = event.target.value;
    confirmAlert({
      title: 'Change Status',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: id, status: selectedStatus})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    });
  }
  return (
    <>
      <div className="form_input">
        <select className="form_global" value={order.status} onChange={handleStatusChange}  disabled={order.status === 5 ? true : false}>
          {Object.entries(orderType).length &&
              <>
                {Object.entries(orderType).map(([key, value]) => (
                    <option key={`option_item_${ orderType[key].id }`} value={ orderType[key].id }>
                      { orderType[key].type }
                    </option>
                ))}
              </>
          }
        </select>
      </div>
    </>
  );
}

export default OrderTableStatusComponent;